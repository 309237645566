@import "../../../assets/style/media.less"; // 媒体查询
@import "../../../assets/style/classname.less"; // 类名
// The prefix to use on all css classes from ant.
@ant-prefix: khAnt;

@primary-color: #2d55a5; // 全局主色
// @link-color: #1890ff; // 链接色
// @success-color: #52c41a; // 成功色
// @warning-color: #faad14; // 警告色
// @error-color: #f5222d; // 错误色
// @font-size-base: 14px; // 主字号
// @heading-color: rgba(0, 0, 0, 0.85); // 标题色
// @text-color: rgba(0, 0, 0, 0.65); // 主文本色
// @text-color-secondary: rgba(0, 0, 0, 0.45); // 次文本色
// @disabled-color: rgba(0, 0, 0, 0.25); // 失效色
// @border-radius-base: 2px; // 组件/浮层圆角
// @border-color-base: #d9d9d9; // 边框色
// @box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
//   0 9px 28px 8px rgba(0, 0, 0, 0.05); // 浮层阴影
// @import '../variable.less';
@cardHeight-1: 180px;
@cardHeight-2: 114px;

@{TWBaseMenuProCardPrefixCls} {
  background-color: #fff;
  height: @cardHeight-1; // default height
  &.@{ant-prefix}-card {
    border-radius: 8px;
    border: 3px solid transparent;
  }
  &.active {
    border-color: #5585e4;
  }
  &.h-type-1 {
    height: @cardHeight-1;
  }
  &.h-type-2 {
    height: @cardHeight-2;
  }
  .@{ant-prefix}-card-head-title {
    height: 42px;
    padding: 8px 0 0;
    color: rgba(0, 0, 0, 0.8);
    line-height: 34px;
    font-family: "MicrosoftYaHeiSemibold";
  }
  &-col.@{ant-prefix}-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
}

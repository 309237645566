@import "../../assets/style/media.less"; // 媒体查询
@import "../../assets/style/classname.less"; // 类名
// The prefix to use on all css classes from ant.
@ant-prefix: khAnt;

@primary-color: #2d55a5; // 全局主色
// @link-color: #1890ff; // 链接色
// @success-color: #52c41a; // 成功色
// @warning-color: #faad14; // 警告色
// @error-color: #f5222d; // 错误色
// @font-size-base: 14px; // 主字号
// @heading-color: rgba(0, 0, 0, 0.85); // 标题色
// @text-color: rgba(0, 0, 0, 0.65); // 主文本色
// @text-color-secondary: rgba(0, 0, 0, 0.45); // 次文本色
// @disabled-color: rgba(0, 0, 0, 0.25); // 失效色
// @border-radius-base: 2px; // 组件/浮层圆角
// @border-color-base: #d9d9d9; // 边框色
// @box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
//   0 9px 28px 8px rgba(0, 0, 0, 0.05); // 浮层阴影
// @import './variable.less';
@menu-width: 424px;

.menu-open-mixin(@menu-width) {
  flex: 0 0 @menu-width;
  width: @menu-width;
  min-width: @menu-width;
  max-width: @menu-width;
}
.menu-side-mixin(@menu-width) {
  left: -@menu-width;
  flex: 0 0 @menu-width;
  width: @menu-width;
}

@{TWBaseMenuPrefixCls} {
  &-place {
    flex: 0 0 0;
    width: 0;
    min-width: 0;
    max-width: 0;
    overflow: hidden;
    transition: background-color 0.3s ease 0s, min-width 0.3s ease 0s,
      width 0.3s ease 0s, flex-basis 0.3s ease 0s,
      max-width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    &.menu-open {
      .menu-open-mixin(@menu-width);
      .responsive("xl", {
        .menu-open-mixin(@menu-width * 0.85);
      });
      .responsive("lg", {
        .menu-open-mixin(@menu-width * 0.8);
      });
      .responsive("md", {
        .menu-open-mixin(@menu-width * 0.75);
      });
      .responsive("sm", {
        .menu-open-mixin(@menu-width * 0.65);
      });
      .responsive("xs", {
        .menu-open-mixin(@menu-width * 0.6);
      });
    }
  }
  &-side {
    position: relative;
    height: 100%;
    padding: 0 24px;
    background: rgba(0, 0, 0, 0.8);
    transition: left 0.3s ease 0s;
    overflow: hidden;
    width: 100%;

    &.menu-open {
      left: 0;
    }

    // 亮色主题
    .theme-light & {
      background: rgba(0, 0, 0, 0.05) !important;
    }

    // 蓝色主题
    .theme-blue & {
      background: rgba(0, 108, 255, 0.4) !important;
    }
  }
  &_title {
    // width: 376px;
    height: 64px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 28px;
    line-height: 64px;
    text-align: center;
    font-family: "MicrosoftYaHeiSemibold";
    &.without-collapse {
      margin-bottom: 24px;
    }

    .theme-light &,
    .theme-blue & {
      color: rgba(0, 0, 0, 0.8);
    }
  }
  &_content {
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100% - 64px);
    //firefox滚动条隐藏
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-thumb {
      width: 0;
    }
  }
  // hack: 解决edge浏览器内阴影问题
  &_mask_padding {
    width: 100%;
    height: 135px;
  }
  &_mask {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 135px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.03) 15%,
      rgba(0, 0, 0, 0.74) 69%,
      rgba(0, 0, 0, 1) 100%
    );
    pointer-events: none;

    .theme-light & {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.03) 15%,
        rgba(255, 255, 255, 0.74) 69%,
        rgba(255, 255, 255, 1) 100%
      );
    }

    .theme-blue & {
      background: linear-gradient(
        180deg,
        rgba(0, 108, 255, 0) 0%,
        rgba(0, 108, 255, 0.03) 15%,
        rgba(0, 108, 255, 0.74) 69%,
        rgba(0, 108, 255, 1) 100%
      );
    }
  }
  &_collapse.@{ant-prefix}-collapse {
    position: relative;
    margin-right: -14px;
    padding-right: 14px;
    overflow-x: hidden;
    overflow-y: scroll;
    background: none;
    border: none;
    font-size: 22px;

    // 亮色/蓝色主题
    .theme-light &,
    .theme-blue & {
      .@{ant-prefix}-collapse-item {
        .@{ant-prefix}-collapse-header-text {
          color: rgba(0, 0, 0, 0.8);
        }
      }
      .@{ant-prefix}-collapse-expand-icon{
        .@{ant-prefix}-collapse-arrow{
          color: #000;
        }
      }
    }

    // &::-webkit-scrollbar {
    //   width: 0;
    // }
    // &::-webkit-scrollbar-thumb {
    //   width: 0;
    // }

    .@{ant-prefix}-collapse-item {
      border: none;
      .@{ant-prefix}-collapse-header {
        padding: 12px 0 20px;
        color: rgba(255, 255, 255, 0.8);
        .@{ant-prefix}-collapse-expand-icon {
          position: absolute;
          left: calc(100% - 12px);
          .@{ant-prefix}-collapse-arrow {
            margin-right: 0;
          }
        }
        .@{ant-prefix}-collapse-header-text {
          font-family: "MicrosoftYaHeiSemibold";
        }
      }
    }
    .@{ant-prefix}-collapse-content {
      background: none;
      border: none;
      > .@{ant-prefix}-collapse-content-box {
        padding: 0;
      }
    }

    .menu-card-wrap {
      // display: flex;
      // flex-wrap: wrap;
      // align-content: flex-start;
      min-height: 126px;
      // column-gap: 22px;
      // column-gap: 16px;
      // row-gap: 24px;
    }
  }
  &-entry {
    position: fixed;
    bottom: 16px;
    left: 34px;
    z-index: 1000;
    width: 60px;
    height: 60px;
    background-image: url("~@/assets/images/menu/entry.png");
    background-size: cover;
    cursor: pointer;
    &:hover {
      background-image: url("~@/assets/images/menu/entry_hover.png");
    }
  }
}
